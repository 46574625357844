@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Satoshi';
    src: url("/src/assets/fonts/Satoshi-Variable.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;700;800;900&display=swap');

@layer components {
    .glass-bg {
        @apply bg-[#7d7979]/[.3] backdrop-blur-md shadow-[0_4px_30px_rgba(0,0,0,0.1)]
    }
    .dropdown-bg {
        @apply bg-[#FEF8F5]/[.92] backdrop-blur-sm shadow-[0_4px_4px_rgba(0,0,0,0.25)]
    } 
    .dropdown:hover .dropdown-content {display: block;}
    
    .shadowtext {
        text-shadow: 5px 5px #fff;
    }
    .display-none {
        display: none;
    }
    .title {
        margin: 0;
        opacity: 40%;
        /* color: ; */
        text-shadow: -1px 1px 0px #fff,
                      1px 1px 0px #fff,
                      1px 1px 0px #fff,
                      -1px -1px 0 #fff;
    }
    .solution {
        margin: 0;
        color: #FFF6F3;
        text-shadow: -1px 1px 0px #FF4B0B,
                      1px 1px 0 #FF4B0B,
                      1px -1px 0 #FF4B0B,
                      -1px -1px 0 #FF4B0B;
    }
    .case-study {
        margin: 0;
        color: #FF4B0B;
        text-shadow: -1px 1px 0px #FFF6F3,
                      1px 1px 0 #FFF6F3,
                      1px -1px 0 #FFF6F3,
                      -1px -1px 0 #FFF6F3;
    }
    .payment-pro {
        margin: 0;
        color:  #EBF2FF;
        text-shadow: -1px 1px 0px #032052,
                      1px 1px 0 #032052,
                      1px -1px 0 #032052,
                      -1px -1px 0 #032052;
    }
    .issuer-pro {
        
        margin: 0;
        color: #FAF4E8;
        text-shadow: -1px 1px 0px #032052,
                      1px 1px 0 #032052,
                      1px -1px 0 #032052,
                      -1px -1px 0 #032052;
    }
    .card-shadow {
        box-shadow: 10px 10px #0e49ab;
    }

    .modal-open {
        overflow: hidden;
        height: screen;
    }
}